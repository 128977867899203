global.Mustache = require('mustache');
global.$ = require('jquery');
global.isotope = require('isotope-layout');
global.packery = require('isotope-packery');

import { io } from "socket.io-client";

global.socket = io(window.location.origin, {
   secure: true,
   // secure: window.location.protocol.indexOf('https')>-1,
   transports: [ "websocket", "polling" ]
});

// Modules
import Entries from "./modules/entries";
import Ux from "./modules/ux";
import Log from "./modules/log";

var ts = localStorage.getItem('hyperimageatlas-pages-timestamp');
var timestampNotTooOld = ts && parseInt(ts) + 720000 > Date.now();

function launch() {
   if($('body').hasClass('home')) {
      new Ux();
   }
   if($('body').hasClass('about')) {
      $('body').removeClass('blur');
      
      for(var i=0;i<20;i++) {
         if(!window.entries.entries[i]['about-hyperimage-atlas-2022']) break;
         let copy = window.entries.entries[i]['about-hyperimage-atlas-2022'].replaceAll('\n\n','<div class="spacer"></div>');
         $('[data-id="about-text"]').append(`<p>${copy}</p>`);
      }
      $('[data-id="about-text"]').append(`<div class="spacer"></div>`);
      
      for(var j=27;j<35;j++) {
         if(!window.entries.entries[j]['readme']) break;
         let copy2 = `${window.entries.entries[j]['readme'].replaceAll('\n\n','<div class="spacer"></div>')}`;
         $('[data-id="about-text"]').append(`<p class='copy'>${copy2}</p>`);
      }
      $('[data-id="about-text"]').append(`<br>`);
      
      for(var n=20;n<26;n++) {
         if(!window.entries.entries[n]['role']) break;
         let copy3 = `${window.entries.entries[n]['role']}: ${window.entries.entries[n]['first-name']} ${window.entries.entries[n]['last-name']}<br>`;
         $('[data-id="about-text"]').append(`<p class='copy'>${copy3}</p>`);
      }


      let copyright = `
         <p class='copy'>&copy; ${new Date().getFullYear()} Sheung Yiu.</p>
      `;
      $('[data-id="about-text"]').append(copyright);
   }
   if($('body').hasClass('bibliography')) {
      $('body').removeClass('blur');
      /*
      let realEntries = [];
      for(let i=0; i<entries.entries.length; i++) {
         if(entries.entries[i].title!==''){
            realEntries.push(entries.entries[i])
         }
      }
      realEntries.forEach(e => {
         if(e.important == 'Y') {
            Object.keys(e).forEach(k => {
               if(k.indexOf('isbn')>=0) {
                  if(e[k]) {
                     if(e[k].indexOf('.')>=0) {
                        // doi
                        $('[data-id="bibliography"]').append(`<p data-doi="${e[k]}">${e[k]}</p>`);
                     } else {
                        $('[data-id="bibliography"]').append(`<p data-isbn="${e[k]}">${e[k]}</p>`);
                     }
                  }
               }
            });
            $('[data-doi]').each(function(){
               let DOI = $(this).attr('data-doi').indexOf('.org')>=0 ? $(this).attr('data-doi').split('doi.org/')[1] : $(this).attr('data-doi');
               let fetchUrl = `https://api.crossref.org/works/${DOI}`;
                     fetch(fetchUrl)
                     // .catch(error => {
                     //    console.error('Error:', error);
                     // })
                     .then(response => response.json())
                     .then(data => {
                        if(Object.keys(data).length==0){
                           return;
                        }
                        
                        window.fetched = data;
                        // DOI
                        if(fetchUrl.indexOf('crossref')>=0 && data.message) {
                           let names = '';
                           for(var i=0;i<data.message.author.length;i++) {
                              names += data.message.author[i].family + ', '+data.message.author[i].given;
                              if(i == data.message.author.length-2) {
                                 names += ', and ';
                              } else if(data.message.author.length>2) names += ', ';
                           }
                           let dateparts = data.message.issued['date-parts'][0];
                           let fullString = `<p>${names}. "${data.message.title[0]}." ${data.message['short-container-title']}, vol. ${data.message.volume}, no. ${data.message.issue}, ${dateparts[2]}.${dateparts[1]}.${dateparts[0]}, pp. ${data.message.page}. ${data.message.publisher}, doi: <a href="${data.message.URL}" onclick="window.open('${data.message.URL}')">${data.message.DOI}</a>.</p>`;
                           $(this).addClass('ok').html(fullString);
                           
                           // ISBN
                        }
                     });
            });
            $('[data-isbn]').each(function(){
               let ISBN = $(this).attr('data-isbn');
               let fetchUrl = `https://openlibrary.org/api/books?bibkeys=ISBN:${ISBN}&format=json&jscmd=data`;
               fetch(fetchUrl)
                     // .catch(error => {
                     //    console.error('Error:', error);
                     // })
                     .then(response => response.json())
                     .then(data => {
                        if(Object.keys(data).length==0){
                           return;
                        }
                  Object.keys(data).forEach(key => {
                     let entryData = data[key];
                     
                     let names = '';
                     for(var i=0;i<entryData.authors.length;i++) {
                        if(entryData.authors[i].name.split(' ').length>=3) {
                           // names += entryData.authors[i].name;
                           names += entryData.authors[i].name.split(' ')[entryData.authors[i].name.split(' ').length-1] + ', '+entryData.authors[i].name.split(' ')[0]+' '+entryData.authors[i].name.split(' ')[1];
                        } else {
                           names += entryData.authors[i].name.split(' ')[entryData.authors[i].name.split(' ').length-1] + ', '+entryData.authors[i].name.split(' ')[0];
                        }
                        if(i == entryData.authors.length-2) {
                           names += ', and ';
                        } else if(entryData.authors.length>2 && i < entryData.authors.length-1) names += ', ';
                     }
                     let fullString = `<p>${names}. <em>${entryData.title}</em>. ${entryData.publishers[0].name}, ${entryData.publish_date}. <a href="${entryData.url}')" onclick="window.open('${entryData.url}')">${key}</a>.</p>`;
                     $(this).addClass('ok').html(fullString);
                  });
               });
            });
            // keep only unique texts
            window.uniqueTexts = [];
            $('[data-doi].ok').each(function(){
               if(window.uniqueTexts.indexOf($(this).text())<0) {
                  window.uniqueTexts.push($(this).text());
                  $(this).remove();
               } else {
                  $(this).remove();
               }
            });
            $('[data-isbn].ok').each(function(){
               if(window.uniqueTexts.indexOf($(this).text())<0) {
                  window.uniqueTexts.push($(this).text());
                  $(this).remove();
               } else {
                  $(this).remove();
               }
            });

            setInterval(() => {
               // sort all alphabetically
               let sorted = window.uniqueTexts.sort();
               $('.bibban').html('');
               sorted.forEach(s => {
                  $('.bibban').append(`<p>${s}</p>`);
               });
            }, 3000);
         }
     });
     */
   }
}

if(localStorage.getItem('hyperimageatlas-pages') && timestampNotTooOld) {
   console.log('localStorage OK, timestamp valid for:', (parseInt(ts) + 120000) - Date.now()); // 20 min
   // console.log('localStorage OK, timestamp valid for:', (parseInt(ts) + 720000) - Date.now());
   window.pages = JSON.parse(localStorage.getItem('hyperimageatlas-pages'));
   console.log('Using pages from localStorage:', pages);
   window.entries = new Entries(pages);
   launch();
} else {
   socket.on('receivePages', (pages) => {
      console.log('Received pages:', pages);
      localStorage.setItem('hyperimageatlas-pages-timestamp', Date.now());
      localStorage.setItem('hyperimageatlas-pages', JSON.stringify(pages));
      window.pages = pages;
      window.entries = new Entries(pages);
      launch();
   });
   socket.emit('getRows');
}

socket.on('addWorksheet', (ws) => {
   console.log(ws);
});

if($('body').hasClass('home')) {
   new Log();
   
   $(window).on('keyup', function(e){
      // if(e.key == 'ArrowUp') {
      //    $('nav.cats .cur').prev().click();
      // } else if(e.key == 'ArrowDown') {
      //    $('nav.cats .cur').next().click();
      // }
   })
   
   console.log('Fetching data...');
   
   window.loadDots = 0;
   window.loadInterval = setInterval(function(){
      window.loadDots++;
      var t = '.';
      for(var i=0; i<window.loadDots;i++){
         t += '.';
      }
      if(window.loadDots > 3){
         window.loadDots = 0;
         t = '.';
      }
      $('.loading i').text(t);
   }, 200);
}
