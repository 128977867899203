import Entry from "./entry";

export default class Entries {
   constructor(pages) {
      console.log('Constructing Entries');
      var _this = this;
      this.entries = [];
      this.themes = [];

      for(let i = 0; i < pages.length; i++) {
         var headers = pages[i].headers;

         pages[i].rows.forEach(row => {
            _this.entries.push(new Entry(row, headers));

            // if last
            if(i==pages.length-1) {
               setTimeout(function(){
                  $('.loading').fadeOut(400, function(){ 
                     // $('.loading').remove(); 
                  });
               }, 1000);
            }
         });
      }

      _this.entries.forEach(a => {
         if(a.theme && _this.themes.indexOf(a.theme) < 0) {
            _this.themes.push(a.theme);
         }
      });
   }
}