function calculateAdjustmentFactor(currentScale, newScale) {
   // This function will now dynamically adjust based on the zoom level,
   // making adjustments more gentle as we zoom out from higher scales,
   // and more aggressive as we approach a scale of 1.
   
   const minFactor = 0.88;  // More aggressive adjustment closer to scale of 1.
   const maxFactor = 0.899; // Less aggressive adjustment at higher scales.
   
   // Adjust the factor dynamically based on scale. The factor decreases
   // (i.e., adjustment becomes more aggressive) as the scale approaches 1,
   // and increases (less aggressive) as the scale gets larger.
   // let scaleDistanceFromOne = 1;
   let scaleDistanceFromOne = Math.abs(0.5 - currentScale);
   let adjustmentRange = maxFactor - minFactor;
   let adjustmentFactor = minFactor + (adjustmentRange * scaleDistanceFromOne);
   // let adjustmentFactor = maxFactor - (adjustmentRange * scaleDistanceFromOne);

   // Ensure the adjustment factor stays within our defined bounds.
   adjustmentFactor = Math.max(minFactor, Math.min(maxFactor, adjustmentFactor));

   // If we are zooming out and already close to scale 1, we apply a more aggressive adjustment.
   if (newScale < currentScale && currentScale <= 0.5) {
       adjustmentFactor = 0.3;
   }
// 
   return adjustmentFactor;
}



export default class mainView {
   constructor(el){

      const self = this;
      
      this.state = {
          mousedown: false,
          mousedownX: 0,
          mousedownY: 0,
          mouseX: 0,
          mouseY: 0,
          translateX: 0,
          translateY: 0,
          scale: .8
      };

      $('body.blur').removeClass('blur');

      this.updateTransform();

      this.binds();

      // $('body').on('mousedown', () => {
      //    self.mousedown = true;
      // }).on('mouseup', () => {
      //    self.mousedown = false;
      // }).on('mousemove', (event) => {
      //    if(!self.mousedown) { return; }

         

      // }).off('mousewheel').on('mousewheel', function(event){
      //    event = event.originalEvent || event;

      //    let zoomVal = ((event.deltaY||0.0) * 0.0001) * -1;
      //    let SCALEY = 1.0;
      //    if($('main').attr('style')) {
      //       SCALEY = $('main').attr('style').indexOf('scale')>=0 ? parseFloat($('main').attr('style').split('scale(')[1].split(')')[0]) : 1.0;
      //    }
      //    let curZoom = SCALEY;
      //    let zoomAdjust = curZoom + zoomVal;
         
      //    var transformCss = `scale(${zoomAdjust}) `;

      //    $('main').css({
      //       'transform': transformCss,
      //       'transform-origin' : `${xPercent}% ${yPercent}%`
      //    });
      // });
   }

   binds() {
      const self = this;
      var state = this.state;

      $('body').on('mouseenter', () => {
         $('main').removeClass('mousedown zooming zooming-out zooming-int');
         window.preventClick = false;
         state.mousedown = false;
      }).on('mousedown', (event) => {
         // if($('.box.open').length!==0) return;
         $('main').addClass('mousedown');
         state.mousedown = true;
         state.mouseX = event.pageX;
         state.mouseY = event.pageY;
         state.mousedownX = event.pageX;
         state.mousedownY = event.pageY;
         clearTimeout(window.clearPrevent);
      }).on('mouseup', () => {
         // if($('.box.open').length!==0) return;
         $('main').removeClass('mousedown');
         state.mousedown = false;
         window.clearPrevent = setTimeout(function(){
            window.preventClick = false;
         }, 10);
      }).on('mousemove', (event) => {
         // if($('.box.open').length!==0) return;
         if (!state.mousedown) { return; }
         clearTimeout(window.clearPrevent);
         window.preventClick = true;
         
         // Calculate movement delta
         let dx = event.pageX - state.mouseX;
         let dy = event.pageY - state.mouseY;
         
         // Update the state
         state.translateX += dx;
         state.translateY += dy;

         // Apply the transform
         self.updateTransform();
         
         // Update the last mouse position
         state.mouseX = event.pageX;
         state.mouseY = event.pageY;

         if(Math.abs(state.mousedownX - state.mouseX) > 10 || Math.abs(state.mousedownY - state.mouseY) > 10) {
            window.preventClick = true;
         }
      }).on('mousewheel', function(event) {
         // if($('.box.open').length!==0) return;

         event = event.originalEvent || event;

         if($(event.target).parents('.box.open').length>0) return;
         event.preventDefault();

         $('main').addClass('zooming');
         
         let zoomVal = ((event.deltaY || 0.0) * 0.001) * -1;
         
         // Calculate the new scale
         let newScale = Math.min(Math.max(0.4, state.scale + zoomVal), 1.5);
         

         let adjustmentFactor = calculateAdjustmentFactor(state.scale, newScale);
         // console.log(adjustmentFactor);
         // Normalize translate values when zooming out
         if (newScale < state.scale) {

            $('main').removeClass('zooming-in').addClass('zooming-out');

            // state.translateX *= adjustmentFactor;
            // state.translateY *= adjustmentFactor;

            
            // state.translateX *= 0.95;
            // state.translateY *= 0.95;
         } else {
            $('main').removeClass('zooming-out').addClass('zooming-in');
            
            // Calculate the position to zoom towards
            let mouseX = event.pageX;
            let mouseY = event.pageY;
            // let mainOffset = $('main').offset();
            let localX = (mouseX - window.innerWidth*.5) * 1.05; // - mainOffset.left;
            let localY = (mouseY - window.innerHeight*.5) * 1.05; // - mainOffset.top;
            
            // Adjust translation to simulate zooming towards the mouse position
            let scaleFactor = (newScale / state.scale);
            // state.translateX -= (localX * scaleFactor - localX);
            // state.translateY -= (localY * scaleFactor - localY);

            // state.translateX -= (localX * scaleFactor - localX) * (3 - adjustmentFactor); // More aggressive when closer to 1
            // state.translateY -= (localY * scaleFactor - localY) * (3 - adjustmentFactor);
         }
         
         state.scale = newScale;
         
         // Apply the transform
         self.updateTransform();

         clearTimeout(window.removeZooming);
         window.removeZooming = setTimeout(function(){
            $('main').removeClass('zooming zooming-in zooming-out');
         }, 100);
      });

      // $(window).on('resize', function(){
      //    clearTimeout(window.resizeTimeout);
      //    window.resizeTimeout = setTimeout(function(){
      //       if(window.relators.length>0) {
      //          for(var i=0; i<window.relators.length; i++) {
      //             window.relators[i].alignRelatedBoxes(window.relators[i]._box);
      //          }
      //       }
      //    }, 1000);
      // });
   }

   updateTransform() {
      const state = this.state;

      // state.translateX = Math.max(state.translateX, -window.innerWidth);
      // state.translateX = Math.min(state.translateX, window.innerWidth);

      // state.translateY = Math.max(state.translateY, -window.innerHeight);
      // state.translateY = Math.min(state.translateY, window.innerHeight);

      var transformCss = `translate(${state.translateX}px, ${state.translateY}px) scale(${state.scale})`;
      $('main').css({
          'transform': transformCss
      });
   }
}