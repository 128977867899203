import Player from '@vimeo/player';

export default class Entry {
   constructor(row, headers) {
      const self = this;

      for(var i = 0; i < headers.length; i++) {
         let key = headers[i];
         key = key.replace(/[^a-zA-Z0-9\s]+/g, '').replaceAll(' ','-').toLowerCase();
         this[key] = row.data[i];
         if(this['type']){
            // console.log(this.type, this.title);
            this._cat = this['type'];
            // category;
            if(key=='type') {
               // console.log(row.data[i], row);
            }
         }
         // this.prototype[headers[i]] = this.data[i];
      }

      this.relatedBoxes = [];
      window.relators = [];
 
      // if(this['status'] !== 'Draft') {
      //    this.createBlock();
      // } else {
      //    console.log(`DRAFT: "${this['title']}" - skipping`);
      // }
      this.closeRelateds = this.closeRelateds.bind(this);
      this.clearRelateds = this.clearRelateds.bind(this);
      this.alignRelatedBoxes = this.alignRelatedBoxes.bind(this);
      // this.drawLine = this.drawLine.bind(this);
      this.drawAllLines = this.drawAllLines.bind(this);
      this.getPosition = this.getPosition.bind(this);

      this.calculateQuadraticCurveLength = this.calculateQuadraticCurveLength.bind(this);
      this.getQuadraticBezierPoint = this.getQuadraticBezierPoint.bind(this);

      this.calculateCubicCurveLength = this.calculateCubicCurveLength.bind(this);
      this.getCubicBezierPoint = this.getCubicBezierPoint.bind(this);

      this.createBlock();

   }

   createBlock(){
      const t = this;
      // if($(`.boxes.${this._cat}`).length==0){
      //    if(this._cat) {
      //       $('main').append(`<content class='boxes ${this._cat}'><div class='boxes-inner'><div class='box-backdrop'></div></div></content>`);
      //       $('nav.cats').prepend(`<a href='#${this._cat}'>${this._cat}</a>`);
      //    }
      // }

      let boxCss = '';
      let boxClass = '';

      let title = '';
      let authors = '';
      let figure = '';
      let embed = '';
      let sources = [];
      let sourcesObj = {};
      let sourcesResult = null;
      let isbn_dois = '';
      let relateds = [];
      let strengths = [];
      let year = '';
      let medialink = '';
      let figcaption = '';

      window.mouseovers = [];
      window.mouseleaves = [];

      title = 
         this['title'] ||
         this['concept-bold-text-represents-key-concepts'] ||
         this['artwork-title'] ||
         this['concept-name'];

      var important = this['important'] == 'Y';
      if(this['status'] == 'Draft') important = false;
      if(!important) boxClass += 'unimportant';
      if(!important) return false;
      
      if(!title) return false;

      Object.keys(this).forEach(key => {
         if(key.indexOf('description')>=0) {
            this.description = this[key];
         }

         if(key.indexOf('author')==0) {
            if(this[key]) {
               if(authors) authors += ', ';
               authors += this[key];
            }
         }

         if(key.indexOf('year')==0) {
            if(this[key]) {
               if(year) year += ', ';
               year += this[key];
            }
         }

         if (key.startsWith("source-") && key.indexOf('author')>=0) {
            // console.log(key);
            const parts = key.split("-");
            const sourceIndex = parts[1];
            const authorIndex = parts[3];
            const namePart = parts[4]; // 'first' or 'last'
            const value = this[key];

            if(!value) return;

            // console.log(parts);
            
            if (!sourcesObj[sourceIndex]) {
               sourcesObj[sourceIndex] = [];
            }
            
            if (!sourcesObj[sourceIndex][authorIndex - 1]) {
               sourcesObj[sourceIndex][authorIndex - 1] = { firstName: "", lastName: "" };
            }
            
            if (namePart === "first") {
               sourcesObj[sourceIndex][authorIndex - 1].firstName = value;
            } else if (namePart === "last") {
               sourcesObj[sourceIndex][authorIndex - 1].lastName = value;
            }

            sourcesResult = Object.keys(sourcesObj).map(sourceIndex => ({
               source: `Source ${sourceIndex}`,
               authors: sourcesObj[sourceIndex]
            }));

            // console.log('sourcesObj', sourcesResult);
         } else if (key.indexOf('source')==0) {
            if(this[key]) {
               sources.push(this[key]);
            }
         }

         if(key.indexOf('isbn-doi')==0) {
            if(this[key]) {
               // console.log(this[key]); // isbn 
               if(isbn_dois) isbn_dois += ', ';
               isbn_dois += this[key];
            }
         }

         if(key.indexOf('related')==0){
            if(this[key]) {
               relateds.push(this[key]);

               // also get strength value
               // "strength 1" is for "related concept 1"
            }
         }
         if(key.toLowerCase().indexOf('strength')==0){
            if(this[key]) {
               strengths.push(this[key]);
            }
         }

         if(key.indexOf('media-link')==0) {
            if(this[key]) {
               medialink += this[key];
            }
         }
      });

      this._relateds = relateds;
      this._strengths = strengths;

      // mostly replace AUTHORS with ISBN/DOI
      // if(this['isbn'] || this['doi']) {
      //    authors = '';
      //    if(this['isbn']) authors += `<a href='https://www.worldcat.org/isbn/${this['isbn']}' target='_blank'>ISBN: ${this['isbn']}</a>`;
      //    if(this['doi']) authors += `<a href='https://doi.org/${this['doi']}' target='_blank'>DOI: ${this['doi']}</a>`;
      // }

      
      if(this['image-link']){
         // figure = `<img src="${this['image-link']}">`;
         figure = new Image();
         figure.src = this['image-link'];
         figure.onerror = function(){
            $(`img[src="${figure.src}"]`).parent().remove();
         }

         if(this['image-caption']){
            figcaption = `<figcaption>${this['image-caption']}</figcaption>`;
         }
      }

      if(this['video-link']){
      //    this.createBlock();
         embed = '';
         // if(this['video-link'].indexOf('youtube')>=0 || this['video-link'].indexOf('youtu.be')>=0) {
         if(this['video-link'].indexOf('youtube')>=0) {
            var embedElement = document.createElement('iframe');

            if(this['video-link'].split('v=').length<2) return;

            let parseYoutubeId = this['video-link'].split('v=')[1];
            let ampersandPosition = parseYoutubeId.indexOf('&');
            if(ampersandPosition != -1) {
               parseYoutubeId = parseYoutubeId.substring(0, ampersandPosition);
            }
            let youtubeUrl = `https://www.youtube.com/embed/${parseYoutubeId}?enablejsapi=1`;
            embedElement.videoId = parseYoutubeId;
            embedElement.src = youtubeUrl;
            embedElement.type = 'youtube';
         } else if(this['video-link'].indexOf('vimeo')>=0) {
            var embedElement = document.createElement('iframe');
            let parseVimeoId = this['video-link'].split('.com/')[1];
            
            if(parseVimeoId.indexOf('/')>=0) {
               parseVimeoId = parseVimeoId.replaceAll('/','?h=');
            }

            let vimeoUrl = `https://player.vimeo.com/video/${parseVimeoId}`;
            embedElement.src = vimeoUrl;
            embedElement.videoId = parseVimeoId;
            embedElement.type = 'vimeo';
         }

         if(embedElement) {
            embed = `<iframe></iframe>`;
            embedElement.dataset.url = this['video-link'];
            embedElement.width = 640;
            embedElement.height = 360;
            embedElement.frameborder = 0;
            embedElement.allow = 'autoplay; fullscreen';
            embedElement.allowfullscreen = true;
            this._embedElement = embedElement;

            if(!this['image-link']) {
               let thumbId = embedElement.videoId.replaceAll('?h=',':');
               figure = document.createElement('div');
               figure.className = 'videothumb';
               let figureImg = new Image();
               // figureImg.className = 'thumb';
               figureImg.src = `https://vumbnail.com/${thumbId}.jpg`
               figureImg.onerror = function(){ $(`img[src="${figureImg.src}"]`).parents('figure').remove() }
               figure.innerHTML = figureImg.outerHTML;
            }
         }
      }

      // sources
      // this._sources = [];
      // Object.keys(this).forEach(key => {
      //    if(key.indexOf('source')==0) {
      //       console.log(key);
      //       let sourceNum = key.split('source-')[1][0];
      //       if(sourceNum) {
      //          var sourceObj = {
      //             author_1 : this[`source-${sourceNum}-author-1-first-name`] + ' ' + this[`source-${sourceNum}-author-1-last-name'],
      //          };
      //          this._sources[sourceNum].push(sourceObj);
      //       }
      //    }
      // });

      let MAIN_html = '';
      let ASIDE_html = '';
      let contributor = '';

      title = title.replaceAll('<', '&lt').replaceAll('>', '&gt');
      authors = authors.replaceAll('<', '&lt').replaceAll('>', '&gt');

      // if(!sourcesResult) {
         let sourcesHtml = '';
         sources.forEach((source, i) => {
            source = source.replaceAll('<', '&lt').replaceAll('>', '&gt');
            if(source.indexOf('http')==0) {
               sourcesHtml += `<a href='${source}' style="cursor:pointer!important;" onclick="window.open('${source}')">${source}</a>`;
            } else {
               sourcesHtml += `<span>${source}</span>`;
            }
            // sourcesHtml += sources.replaceAll('<', '&lt').replaceAll('>', '&gt');
         });
      // } else {
         let sourcesObjHtml = '';
      if(sourcesResult) {
         sourcesResult.forEach((source, i) => {
            sourcesObjHtml += `<div class='source'>`;
            // sourcesObjHtml += `<div class='source'><span class='source-title'>${source.source}</span>`;
            source.authors.forEach((author, i) => {
               sourcesObjHtml += `<span class='source-author'>${author.lastName}${author.lastName&&author.firstName?', ':''}${author.firstName}</span>`;
            });
            sourcesObjHtml += `</div>`;
         });
      }

      if(embed) MAIN_html += `<div class='embed'>${embed}</div>`;
      if(this.description) MAIN_html += `<div class='desc'>${this.description.replaceAll('\n\n','<br><br>')}</div>`;

      if(isbn_dois){
         var fetchUrls = '';
         var splitted = isbn_dois.split(',');
         for(var i=0; i<splitted.length; i++) {
            splitted[i] = splitted[i].trim();
            var fetchUrl = false;
            if(splitted[i].indexOf('doi')>=0) {
               const DOI = splitted[i].split('doi.org/')[1];
               fetchUrl = `https://api.crossref.org/works/${DOI}`;
            } else {
               const ISBN = splitted[i];
               fetchUrl = `https://openlibrary.org/api/books?bibkeys=ISBN:${ISBN}&format=json&jscmd=data`;
            }
            fetchUrls += `<p data-fetchurl='${fetchUrl}'></p>`;
         }
         if(fetchUrl) ASIDE_html += `<div class='references'>${fetchUrls}</div>`;
      }

      // split links by comma
      if(this.link){
         this.links = this.link.split(',');
         ASIDE_html += `<div class='links'>`;
         for(var i=0;i<this.links.length;i++) {
            ASIDE_html += `<a onclick="window.open('${this.links[i]}')" href="${this.links[i]}">${this.links[i].trim()}</a>`;
         }
         ASIDE_html += `</div>`;
      }

      if(this.contribution && this.contribution=='TRUE'){
         contributor += `<div class='contributed by'>`;
         if(this['title-of-contributor']) contributor += `<span>${this['title-of-contributor']}</span> `;
            contributor += `${this['contributor-first-name']} ${this['contributor-last-name']}`;
            if(this['link-to-contributor']) contributor += `<a href="${this['link-to-contributor']}" onclick="window.open('${this['link-to-contributor']}')>${this['link-to-contributor']}</a>`;
         contributor += `</div>`;
      }

      // media link
      if(medialink){
         ASIDE_html += `<div class='media links'>`;
         // for(var i=0;i<this.links.length;i++) {
            ASIDE_html += `<a onclick="window.open('${medialink}')" href="${medialink}">${medialink.trim()}</a>`;
         // }
         ASIDE_html += `</div>`;
      }

      let status = '';
      if(this.description) status += '<i class="desc">D</i>';
      if(authors) status += '<i class="authors">A</i>';
      if(sourcesHtml!='') status += '<i class="sources">S</i>';
      // if(sourcesObjHtml!='') status += '<i class="scholars">Sch</i>';
      if(relateds.length>0) status += `<i class="has-related">R${relateds.length-1}</i>`;
      if(embed) status += '<i class="embed">E</i>';

      // ${ this._cat ? `<div class='type'>${this._cat}</div>` : ``}

      let marginVal = 200;
      let marginValX = 200;
      // let margins = `margin: ${Math.random()*marginVal}px ${Math.random()*marginVal}px ${Math.random()*marginVal}px ${Math.random()*marginVal}px;`;
      let margins = `margin: ${Math.random()*marginVal + 10}px ${Math.random()*marginVal + 10}px ${Math.random()*marginVal + 10}px ${Math.random()*marginValX + 10}px;`;
      boxCss += margins;

      // create a slug from title
      let slug = title.toLowerCase().replaceAll(' ','-').replaceAll('.','').replaceAll(',','').replaceAll(':','').replaceAll(';','').replaceAll('!','').replaceAll('?','').replaceAll('(','').replaceAll(')','').replaceAll('/','').replaceAll('"','').replaceAll("'",'').replaceAll('&','').replaceAll('’','').replaceAll('‘','').replaceAll('“','').replaceAll('”','').replaceAll('—','-').replaceAll('–','-');
      this._slug = slug;

      if(relateds.length>0){ boxClass += ' has-rel'; }

      var box = $(`
         <article data-cat='${this._cat}' id='${this._slug}' class='box ${this._cat} ${boxClass}' style='${boxCss}' data-theme='${this['theme']}' data-relateds="${relateds.length}">
         ${ figure ? `<figure>${figure.outerHTML}${figcaption}</figure>` : ``}
            <h3 class='title'>${title}</h3>
            ${ authors ? `<div class='authors'>${authors}</div>` : ``}
            <div class='box-meta'>
               ${ year ? `<div class='year'>${year}</div>` : ``}
               ${ this['theme'] ? `<div class='theme'>${this['theme']}</div>` : ``}
            </div>
            ${/* <div class='box-body'> */ ''}
               <div class='box--main box-content'>${MAIN_html}</div>
               <div class='box--aside box-content'>${ASIDE_html}</div>
            ${/* </div> */ ''}
            <div class='status'>${status}</div>
            ${contributor}
            <a href='#close' class="close"></a>
            </article>
         `);
            
      this._box = box;

      $(`.boxes .boxes-inner`).append(box);
      // $(`.boxes.${this._cat} .boxes-inner`).append(box);

      if(figure) {
         $(box).addClass('has-image');

         $(box).find('img').on('error', function(){
            $(box).removeClass('has-image');
            if($('.view-as').val()=='images') {
               $(box).css('display','none');   
            }
         }).on('load', function(){
            // if(window.isotopes){
            //    // clearTimeout(window.removeBlur);
            //    clearTimeout(window.doIsotope);
            //    window.doIsotope = setTimeout(function(){
            //       // $('body').addClass('blur');
            //       window.isotopes[0].layout();
            //       // console.log('ISO ARRANGEMENT BY IMG LOADED');
            //       // window.removeBlur = setTimeout(function(){
            //       $('body').removeClass('blur');
            //       // }, 1000);
            //    }, 21);
            // }
         });
      }


      t.totalRelatedHeight = 0;
               
      t._relateds.forEach((key, index) => {
         
         // Assuming t._relateds contains the keys related to '.box h3' text
         $('.box h3').each(function() {
            if ($(this).text() === key) {
               var box = $(this).closest('.box');
               if (t.relatedBoxes.indexOf(box.get(0)) === -1) {
                  t.relatedBoxes.push(box.get(0));
                  t.totalRelatedHeight += $(box).outerHeight(false);
               }
               // Temporarily setting visibility to hidden to calculate the total height without displaying the boxes
               $(box).css('visibility', 'hidden');
               $(box).attr('data-strength', t._strengths[index]);
               $(box).css('visibility', '');
            }
         });
      });
      // }
      
      if(t.relatedBoxes.length==0) {
         t.relatedBoxes = false;
         // $(t._box).find('.has-related').css('color','red');
         $(t._box).addClass('no-rel');
         // return;
      } else {
         // Object.keys(t.relatedBoxes).forEach(key => {
            // t.relatedBoxes.forEach(function(box, index) {
            // $(t.relatedBoxes[key]).addClass('related');
         // });
      }
      // }

      this.initYoutubePlayer = this.initYoutubePlayer.bind(this);
      this.bindBox();

      this.animate = function() {
         this.drawAllLines();  // Draw all the lines in the current frame
         window.lineAnimate = requestAnimationFrame(this.animate);  // Schedule the next frame
      }
      this.animate = this.animate.bind(this);

      if(!window.lineAnimate) {
         // Start the animation
         window.lineAnimate = requestAnimationFrame(this.animate);
      }
   }

   closeRelateds() {
      const t = this;
      // closes the relateds for this box
      this.relatedBoxes && this.relatedBoxes.forEach(function(box, index) {
         $(box).removeClass('related related-fly');
         if($(box).attr('data-origpos')) {
            $(box).css({
               // 'top': $(box).attr('data-origpos').split(' ')[0],
               // 'left': $(box).attr('data-origpos').split(' ')[1],
            });
         }
      });
      window.relators.splice(window.relators.indexOf(this), 1);

      // t.mouseleaves.splice(window.relators.indexOf(this), 1);
      if(window.relators.length==0) {
         this.clearRelateds();
      } else {
         window.relators.forEach(rel => {
            rel.alignRelatedBoxes(rel._box, false);
         });
         // setTimeout(() => {
            // t.drawLine();
         // }, 50);
      }
   }

   clearRelateds(){
      $('.related').removeClass('related related-fly');
      $('[data-origpos]').each(function(){
         $(this).css({
            // 'top': $(this).attr('data-origpos').split(' ')[0],
            // 'left': $(this).attr('data-origpos').split(' ')[1],
         });
      }).removeAttr('data-origpos');

      $('.hovering').removeClass('hovering');
      $('body > main').removeClass('relating');
      window.relators = [];
      window.mouseovers = [];
      window.mouseleaves = [];
      // $('#lines-canvas').addClass('out');
      // setTimeout(function(){
      if(window.context) {
         window.context.clearRect(0, 0, window.canvas.width, window.canvas.height);
      }
         // $('#lines-canvas').removeClass('out');
      // }, 200);
   }

   initYoutubePlayer() {
      const t = this;
      if(window.YT && window.YT.Player) {
         this._player = new YT.Player(t._embedElement, {
            origin: window.location.origin,
            videoId: t._embedElement.videoId,
            playerVars: {
              'playsinline': 1
            },
            events: {
            }
          });
      } else {
         // repeat this function until YT is defined
         setTimeout(this.initYoutubePlayer, 200);
      }
   }

   bindBox() {
      var t = this;

      $(this._box).on('mouseenter', function(e){
         e.preventDefault();
         
         if(window.preventClick) { return false; }
         // if($('.box-open').length>0) { return false; }
         
         const _this = this;
         
         // if(window.doubleClicked) { return false; }
         
         window.mouseovers.forEach(e => {
            clearTimeout(e);
         });
         
         t.mouseenter = setTimeout(function(){
            if(window.relators.indexOf(t) < 0) {
               window.hoverbox = _this;
               window.lastbox = t;
               
               if(t.relatedBoxes.length>0) {
                  t.alignRelatedBoxes(t._box);
                  $(hoverbox).addClass('hovering');
                  $('body > main').addClass('relating');
                  // -----------------------------------------------------------------------------
                  window.relators.push(t);
                  // t.drawLine();
               }
            }

            // window.forceAlign = setInterval(function(){
            //    window.relators.forEach(rel => {
            //       rel.alignRelatedBoxes(rel._box, false);
            //    });
            // }, 10);

            // window.forceAlignTimeout = setTimeout(function(){
            //    clearInterval(window.forceAlign);
            // }, 1500);
         }, $(this).hasClass('hovering') ? 0 : 1500);
         
         window.mouseovers.push(t.mouseenter);
         
      }).on('mouseleave', function(){
         // if($('.box.open').length!==0) return;

         // window.relators.forEach(rel => {
         //    clearTimeout(rel.mouseenter);
         //    clearTimeout(rel.mouseleave);
         // });

         window.mouseovers.forEach(e => {
            clearTimeout(e);
         });
         // window.mouseleaves.forEach(e => {
         //    clearTimeout(e);
         // });

         // t.mouseleave = setTimeout(function(){
         //    t.clearRelateds();
         //    if(window.relators.indexOf(t) < 0) {
         //       window.relators.splice(window.relators.indexOf(t), 1);
         //    }
         // }, 2000);

         // window.mouseleaves.push(t.mouseleave);

         // clearTimeout(window.flyDelay);
         // clearTimeout(t.mouseleave);
      }).on('click', function(e) {
         const _this = this;
         // console.log(t);
         e.stopPropagation();
         e.preventDefault();
         // window.doubleClicked = true;
         setTimeout(() => {
            // window.doubleClicked = false;
            if(!$(_this).hasClass('hovering')){
               window.preventClick = false;
               $(_this).trigger('mouseenter');
            }
         }, 200);

         if(!window.preventClick) {
            if($(this).hasClass('open')) {
               return;
            }

            // $('.box.open').removeClass('open');
            if($('.box-open').length>0) { 
               $('.box.open .close').click();
            }
            $('body > main').addClass('relating');

            // clearTimeout(window.flyDelay);
            clearTimeout(t.mouseleave);

            // setTimeout(function(){
               
               window.log.add(t);
               
               $(_this).addClass('open').removeClass('related');

               window.lastbox = t;
               window.hoverbox = _this;
               
               $('.box.open .box-content').show();

               if($('.box.open .box-content').text().trim() == '') {
                  $('.box-content').hide();
               }
               
               $('main').addClass('box-open relating');

               window.relators.push(lastbox);

               $(_this).addClass('open').removeClass('related');
               // }
              
               var videoInterval = setInterval(function(){
                  // get video player
                  if(t._embedElement) {
                     if(t._embedElement.type=='youtube') {
                        t.initYoutubePlayer();
                        // t._player = new YouTubePlayer(t._embedElement);
                        // t._player.loadVideoById(t._embedElement.videoId);
                        // t._embedElement.player = t._player;
                     } else if(t._embedElement.type=='vimeo') {
                        t._player = new Player(t._embedElement);
                        t._player.getVideoHeight().then(h => {
                           t._player.getVideoWidth().then(w => {
                              let ratio = (h/w) * 100;
                              $(t._embedElement).parent().css('padding-bottom', ratio+'%');
                           });
                        });
                     }
                     $('.box.open .box--main iframe').replaceWith(t._embedElement);
                     clearInterval(videoInterval);
                  } else {
                     // no embedElement, remove interval
                     clearInterval(videoInterval);
                  }
                  
                  if(_this._player) {
                     // if(t._player) t._player.pause ? t._player.pause() : t._player.pauseVideo();
                     clearInterval(videoInterval);
                     
                     setTimeout(function(){
                        // $(box).find('.box-content').hide().slideDown(300);
                        t._player.play ? t._player.play() : t._player.playVideo();
                        t._player.mute ? t._player.mute() : t._player.setVolume(0); // setVolume for Vimeo
                     }, 100);
                  }
               }, 1000);
               
               // get References
               if($('.box.open [data-fetchurl]').length>0) {
                  setTimeout(function(){
                  
                  $('.box.open [data-fetchurl]').hide();
                  $('.box.open [data-fetchurl]').each(function(){
                     const __this = this;
                     let fetchUrl = $(this).attr('data-fetchurl');
                     fetch(fetchUrl)
                     .catch(error => {
                        console.error('Error:', error);
                        $(__this).hide();
                     })
                     .then(response => response.json())
                     .then(data => {
                        if(Object.keys(data).length==0){
                           $(__this).hide();
                           return;
                        }
                        
                        window.fetched = data;
                        // DOI
                        if(fetchUrl.indexOf('crossref')>=0 && data.message) {
                           let names = '';
                           if(data.message.author){
                              for(var i=0;i<data.message.author.length;i++) {
                                 names += data.message.author[i].family + ', '+data.message.author[i].given;
                                 if(i == data.message.author.length-2) {
                                    names += ', and ';
                                 } else if(data.message.author.length>2) names += ', ';
                              }
                           }
                           let dateparts = data.message.issued['date-parts'][0];
                           let fullString = `<p>${names}. "${data.message.title[0]}." ${data.message['short-container-title']}, vol. ${data.message.volume}, no. ${data.message.issue}, ${dateparts[2]}.${dateparts[1]}.${dateparts[0]}, pp. ${data.message.page}. ${data.message.publisher}, doi: <a href="${data.message.URL}" onclick="window.open('${data.message.URL}')">${data.message.DOI}</a>.</p>`;
                           $(__this).html(fullString);
                           
                           // ISBN
                        } else if(fetchUrl.indexOf('openlibrary')>=0 && data) {
                           Object.keys(data).forEach(key => {
                              let entryData = data[key];
                              
                              let names = '';
                              for(var i=0;i<entryData.authors.length;i++) {
                                 if(entryData.authors[i].name.split(' ').length>=3) {
                                    names += entryData.authors[i].name;
                                 } else {
                                    names += entryData.authors[i].name.split(' ')[entryData.authors[i].name.split(' ').length-1] + ', '+entryData.authors[i].name.split(' ')[0];
                                 }
                                 if(i == entryData.authors.length-2) {
                                    names += ', and ';
                                 } else if(entryData.authors.length>2 && i < entryData.authors.length-1) names += ', ';
                              }
                              let fullString = `<p>${names}. <em>${entryData.title}</em>. ${entryData.publishers[0].name}, ${entryData.publish_date}. <a href="${entryData.url}')" onclick="window.open('${entryData.url}')">${key}</a>.</p>`;
                              $(__this).html(fullString);
                           });
                        }
                        
                        $(__this).show();
                        // $(this).slideDown(300);
                     });
                  }); // each
               }, 50);
               } // data-fetchurls
               
               for(var i=0;i<window.relators.length;i++) {
                  window.relators[i].alignRelatedBoxes(window.relators[i]._box, true);
               }

               $(_this).removeClass('related');
            }
      });

      $(this._box).find('.close').on('click', function(e){
         clearTimeout(window.alignTimeout);

         window.preventClick = true;
         // setTimeout(function(){
            e.preventDefault();
            
            $('.box-content').hide();
            // $('.box-content').slideUp(80);
            $(t._box).removeClass('open');
            if(t._player) t._player.pause ? t._player.pause() : t._player.pauseVideo!==undefined ? t._player.pauseVideo() : '';
            if($('.box.open').length == 0) {
               $('main').removeClass('box-open');
               $('.footer').addClass('in');
               
               if($('.related-fly').length==0) {
                  $('.hovering').removeClass('hovering');
                  $('body > main').removeClass('relating');
               } else if($('.hovering').length>0) {
                  for(var i=0; i<window.relators.length; i++) {
                     $(window.relators[i]._box).addClass('hovering');
                     $('body > main').addClass('relating');
                  }
               } else {
                  t.closeRelateds();
                  // $('.hovering').removeClass('hovering');
                  // $('body > main').removeClass('relating');
               }
            }
            setTimeout(function(){
               window.preventClick = false;
            }, 200);

            // window.relators.splice(window.relators.indexOf(t), 1);

            for(var i=0;i<window.relators.length;i++) {
               $(window.relators[i]._box).addClass('hovering');
               // for(var n=0;n<10;n++) {
                  // setTimeout(function(){
                     if(window.relators[i]){
                        window.relators[i].alignRelatedBoxes(window.relators[i]._box);
                     }
                  // }, 200 * n);
               // }
             }
         // }, 1);
      });
   }

   alignRelatedBoxes(hoverbox, tighten = false) {
      const t = this;

      console.log('alignRelatedBoxes');

      tighten = $('.box.open').length>0 ? true : false;
      // -----------------------------------------------------------------------------
      const relatedBoxes = this.relatedBoxes;
      const totalRelatedHeight = this.totalRelatedHeight;

      clearTimeout(window.alignTimeout);
      window.alignTimeout = setTimeout(function(){
         // console.log(relatedBoxes, totalRelatedHeight, hoverbox);
         // Adjust positions based on the number of related boxes found
         var hoverboxTop = parseFloat($(hoverbox).css('top')) - (parseFloat($(hoverbox).css('margin-top')) + parseFloat($(hoverbox).css('margin-bottom'))) / 2;
         var hoverboxLeft = parseFloat($(hoverbox).css('left')) + parseFloat($(hoverbox).css('margin-left'));
         
         var hoverboxHeight = $(hoverbox).outerHeight(true);
         var hoverboxWidth = $(hoverbox).outerWidth(true);
         var currentTop = 0;

         if (relatedBoxes.length === 1) {
            // Center the single box vertically relative to the hoverbox
            currentTop = hoverboxTop + (hoverboxHeight / 2) - ($(relatedBoxes[0]).outerHeight() / 2);
         } else {
            // Assume totalRelatedHeight is calculated somewhere in your script as the total height of all related boxes
            // Center the stack of boxes vertically relative to the hoverbox
            if(tighten==true) {
               currentTop = (hoverboxTop - window.innerHeight*.5); // - (hoverboxHeight/2);
            } else {
               currentTop = (hoverboxTop - totalRelatedHeight*.5); // + (hoverboxHeight/2);
            }
         }
         
         // sort relatedBoxes by strength
         if(relatedBoxes.length>0){
            // relatedBoxes.sort(function(a, b) {
            //    return $(b).data('strength') - $(a).data('strength');
            // });
            
            // Position each related box
            relatedBoxes.forEach(function(box, idx) {
               // Store original position if not already done
               $(box).not('[data-origpos]').attr('data-origpos', $(box).css('top') + ' ' + $(box).css('left'));

               // let strengthBonus = (100 * (parseInt(box.dataset.strength)/5));
               
               // Calculate left position based on hoverbox position and width
               let left = parseFloat($(hoverbox).css('margin-left')) + parseFloat($(hoverbox).css('left')) + hoverboxWidth + 10;
               // left += strengthBonus; // 5 because its max
               left += (10 * ($('.hovering').length + 1));

               if(tighten==true) {
                  left = hoverboxLeft + hoverboxWidth + Math.random()*100;
                  left += (6 - parseInt($(box).data('strength'))) * 200;

                  // left = `65%`; //calc(100% - ${$(box).outerWidth(true)*.75}px)`;
                  // left = `calc(50% + ${(window.innerWidth/2 - ($(hoverbox).outerWidth(true)))}px)`;
               }
               
               let boxTop = currentTop + (30 * idx);
               // let boxTop = currentTop;
               if(tighten==false) {
                  left += (5 - parseInt($(box).data('strength'))) * 300;
               }
               
               // left += (5 - parseInt($(box).data('strength'))) * 200;
               // boxTop -= (5 - parseInt($(box).data('strength'))) * 100;
               
               if(!$(box).hasClass('open')) {
                  $(box).addClass('related related-fly');

                  box.dataset.startX = hoverboxLeft + hoverboxWidth + $(box).outerWidth(true) / 2; // - parseInt($(box).css('margin-right')); //parseInt($(box).css('left')) + $(box).outerWidth(); // - parseInt($(box).css('margin-right'));
                  box.dataset.startY = parseInt($(box).css('top')) + ($(box).outerHeight(true) / 2) + (10 * idx);
                  
                  box.dataset.endX = hoverboxLeft + hoverboxWidth + $(box).outerWidth(true) / 2; // + $(box).outerWidth(true) / 2; // + parseInt($(box).css('margin-left'));
                  box.dataset.endY = boxTop + ($(box).outerHeight(true) / 2); // + parseInt($(box).css('margin-top'));

                  $(box).css({
                     'top': boxTop + 'px',
                     'left': tighten ? left : left + 'px', // Position to the right of the hoverbox
                     'visibility': 'visible', // Make the box visible
                  });
               }

               currentTop += $(box).outerHeight(false) + (30 * idx);
               
               // Update currentTop for the next box in the stack, including spacing between boxes
               
            });
            
            // t.drawLine();

            /*
            for(var i=0; i < 50; i++) {
               setTimeout(() => { 
                  t.drawLine() 
               }, i*20);
            }
            */
         }
      }, 200);
   }

   getPosition(el, name, idx) {
      var left = parseInt($(el).css('left')) + $(el).outerWidth(false); // - parseInt($(el).css('margin-right'));
      if(name=='right') {
         left = parseInt($(el).css('left')) + parseInt($(el).css('margin-left'));
      }
      var top = parseInt($(el).css('top')) + ($(el).outerHeight(false) / 2) + parseInt($(el).css('margin-top'));
      if(name=='left') {
         top += (10 * idx);
      }
      // console.log(left, top, name);
      return {
         x: left,
         y: top,
      };
   }

   calculateQuadraticCurveLength(p0, p1, p2, subdivisions = 100) {
      let length = 0;
      let prevPoint = p0;
      
      // Subdivide the curve into smaller segments
      for (let i = 1; i <= subdivisions; i++) {
         const t = i / subdivisions; // t goes from 0 to 1
         const currentPoint = this.getQuadraticBezierPoint(t, p0, p1, p2);
         
         // Calculate the distance between the previous point and the current point
         const segmentLength = Math.hypot(currentPoint.x - prevPoint.x, currentPoint.y - prevPoint.y);
         length += segmentLength;
         
         prevPoint = currentPoint;
      }
      
      return length;
   }

   getQuadraticBezierPoint(t, p0, p1, p2) {
      // Quadratic Bézier formula to get the point at parameter t
      const x = Math.pow(1 - t, 2) * p0.x + 2 * (1 - t) * t * p1.x + Math.pow(t, 2) * p2.x;
      const y = Math.pow(1 - t, 2) * p0.y + 2 * (1 - t) * t * p1.y + Math.pow(t, 2) * p2.y;
      return { x, y };
   }


   // Helper function for the cubic Bézier curve length
   calculateCubicCurveLength(p0, p1, p2, p3, subdivisions = 100) {
      let length = 0;
      let prevPoint = p0;
      
      // Subdivide the curve into smaller segments
      for (let i = 1; i <= subdivisions; i++) {
         const t = i / subdivisions; // t goes from 0 to 1
         const currentPoint = this.getCubicBezierPoint(t, p0, p1, p2, p3);
         
         // Calculate the distance between the previous point and the current point
         const segmentLength = Math.hypot(currentPoint.x - prevPoint.x, currentPoint.y - prevPoint.y);
         length += segmentLength;
         
         prevPoint = currentPoint;
      }
      
      return length;
   }

   getCubicBezierPoint(t, p0, p1, p2, p3) {
      // Cubic Bézier formula to get the point at parameter t
      const x = Math.pow(1 - t, 3) * p0.x
      + 3 * Math.pow(1 - t, 2) * t * p1.x
      + 3 * (1 - t) * Math.pow(t, 2) * p2.x
      + Math.pow(t, 3) * p3.x;
      
      const y = Math.pow(1 - t, 3) * p0.y
      + 3 * Math.pow(1 - t, 2) * t * p1.y
      + 3 * (1 - t) * Math.pow(t, 2) * p2.y
      + Math.pow(t, 3) * p3.y;
      
      return { x, y };
   }
  
   
   // 
   drawAllLines(){
      // console.log('drawing');
      if(!window.relators.length) return;

      const t = this;
      if(!window.canvas) {
         window.canvas = document.getElementById('lines-canvas');
         window.context = window.canvas.getContext('2d');
         window.container = $('.boxes');
         window.canvas.width = window.container.outerWidth(true) * 3;
         window.canvas.height = window.container.outerHeight(true);
      }

      const canvas = window.canvas;
      const ctx = window.context;
      const container = window.container;

      if(window.context) {
         window.context.clearRect(0, 0, window.canvas.width, window.canvas.height);
      }

      // Define when to start drawing the line (e.g., 50% through the object's movement)
      const delayPercentage = 0.9;


      window.logged = false;

      // console.log('relators len', window.relators.length);
      
      for (let i = 0; i < window.relators.length; i++) {
         const leftBlock = window.relators[i]._box;
         if(!window.relators[i].relatedBoxes) return;
         window.relators[i].relatedBoxes.forEach(function (block, idx) {
            const pointA = t.getPosition(leftBlock,'left', idx); // Starting point
         
            const rightBlock = block;
            // const pointB = t.getPosition(rightBlock,'right'); // Ending point
            const targetPointB = {
               x: parseFloat(block.dataset.endX), // Target X from dataset
               y: parseFloat(block.dataset.endY)  // Target Y from dataset
           };

           const startPointB = {
               x: parseFloat(block.dataset.startX),  // Start X from dataset
               y: parseFloat(block.dataset.startY)   // Start Y from dataset
           };

           // Current position of pointB (block's current location)
           const pointB = t.getPosition(rightBlock,'right', i);
            // Current position of pointB (block's current location)
         //    const pointB = {
         //       x: parseFloat(block.style.left),  // Assuming block is absolutely positioned (current X)
         //       y: parseFloat(block.style.top)    // Assuming block is absolutely positioned (current Y)
         //   };
            
            // Define a control point (for example, at the midpoint above the line)
            // const controlPoint = {
            //    x: (pointA.x + pointB.x) / 2,
            //    y: Math.min(pointA.y, pointB.y) // Above the midpoint
            // };

            // Calculate total Manhattan distance between startPointB and targetPointB
            const totalDistance = Math.abs(targetPointB.x - startPointB.x) + Math.abs(targetPointB.y - startPointB.y);

            // Calculate current Manhattan distance from startPointB to pointB
            const currentDistance = Math.abs(pointB.x - startPointB.x) + Math.abs(pointB.y - startPointB.y) * 1.1;

            // Calculate the percentage of distance traveled
            const travelPercentage = currentDistance / totalDistance || 0;

            
            // Calculate the total length of the curve (approximately)
            // const curveLength = Math.hypot(targetPointB.x - startPointB.x, targetPointB.y - startPointB.y);

            // Adjust dashOffset based on travelPercentage


         //    const controlPoint = {
         //       x: pointB.x, // Align x with pointA for tighter turns
         //       y: pointA.y // Align y with pointB to make the curve sharper
         //       // x: pointA.x * .95 + (0.2*travelPercentage), // Align x with pointA for tighter turns
         //       // y: pointB.y * (.9 + 0.1*travelPercentage) // Align y with pointB to make the curve sharper
         //   };
                     // Define two control points for the cubic Bézier curve
         
            // const curveLength = t.calculateCubicCurveLength(pointA, controlPoint1, controlPoint2, targetPointB);
           
         //   const dashOffset = curveLength * (1 - travelPercentage);
            // const n = 8; // Higher values will make it more "exponential"
            // const dashOffset =  Math.max(0, curveLength * (1 - Math.pow(travelPercentage, n)));

            
            // console.log(controlPoint);

            // Draw the quadratic curve
            if (travelPercentage > delayPercentage) {
               // Calculate the adjusted travel percentage after the delay
               const adjustedTravelPercentage = (travelPercentage - delayPercentage) / (1 - delayPercentage);
               
               const controlPoint1 = { 
                  x: pointB.x, 
                  y: pointA.y 
               }; // First control point
               const controlPoint2 = { 
                  x: pointA.x, 
                  y: pointB.y 
               }; // Second control point
               const curveLength = t.calculateCubicCurveLength(pointA, controlPoint1, controlPoint2, pointB);
               
               // Exponential dashOffset calculation for acceleration towards the end
               const n = 1; // Higher values will make it more "exponential"
               const dashOffset = Math.max(0, curveLength * (1 - Math.pow(adjustedTravelPercentage, n)));
               // if(!window.logged) {
               //    console.log(dashOffset);
               //    window.logged = true;
               // }
               
               // Draw cubic Bézier curve with animated dash offset
               ctx.beginPath();
               ctx.moveTo(pointA.x, pointA.y);
               ctx.bezierCurveTo(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, pointB.x, pointB.y);
               ctx.strokeStyle = 'white';
               ctx.lineWidth = 2;
               // ctx.lineWidth = block.dataset.strength;
               
               // Set dashing properties and adjust dash offset based on adjusted travel percentage
               ctx.setLineDash([curveLength, curveLength]); // Full dash length equals curve length
               ctx.lineDashOffset = dashOffset; // Set exponential dash offset
               ctx.stroke();
            }

         });
      }
   }
    

   // drawLine() {
   //    const t = this;
   //    // boxes.forEach(box1 => {
   //       const canvas = document.getElementById('lines-canvas');
   //       const context = canvas.getContext('2d');
         
   //       // Get container dimensions to size the canvas
   //       const container = $('.boxes');
   //       canvas.width = container.outerWidth(false) * 1.25;
   //       canvas.height = container.outerHeight(false);

   //       context.clearRect(0, 0, canvas.width, canvas.height);
   //       context.beginPath();

   //    for(let i = 0; i < window.relators.length; i++) {
   //       const leftBlock = window.relators[i]._box;
         
   //       // Get the middle of the left block (adjust based on the block's height)
   //       const leftBlockCenterX = parseInt($(leftBlock).css('left')) + $(leftBlock).outerWidth(true) / 2 - parseInt($(leftBlock).css('margin-right'));
   //       const leftBlockCenterY = parseInt($(leftBlock).css('top')) + ($(leftBlock).outerHeight(true) / 2);
         
   //       // Loop through each right block and draw a line
   //       if(window.relators[i].relatedBoxes) {
   //             window.relators[i].relatedBoxes.forEach(function (block) {
   //             if($(block).get(0).getBoundingClientRect().width == 0 && $(block).get(0).getBoundingClientRect().height == 0 || !$(block).hasClass('related-fly')){
   //             } else {
   //                // const rightBlockPosition = $(block).position();
   //                const rightBlockCenterX = parseInt($(block).css('left')) + parseInt($(block).css('margin-left')); 
   //                const rightBlockCenterY = parseInt($(block).css('top')) + ($(block).outerHeight(true) / 2);
                  
   //                // console.log(
   //                //    block,
   //                //    leftBlockCenterX,
   //                //    leftBlockCenterY,
   //                //    rightBlockCenterX,
   //                //    rightBlockCenterY);
   //                // Draw a line from left block center to right block center
   //                // $('#lines-canvas').addClass('out');
   //                // setTimeout(function(){
   //                   context.moveTo(leftBlockCenterX, leftBlockCenterY);
   //                   context.lineTo(rightBlockCenterX, rightBlockCenterY);
   //                   context.strokeStyle = 'white';
   //                   context.lineWidth = 2;
   //                   context.stroke();
   //                   // $('#lines-canvas').removeClass('out');
   //                // }, 800);

   //             }
   //          });
   //       }
   //    }

   // }
}