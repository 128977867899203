export default class Log {
   constructor() {
      window.log = this;
      this.data = [];
      console.log('Log class initialized');

      this.update = this.update.bind(this);
   }

   add(el) {
      $('.log').addClass('in');

      let count=1;

      let idx = this.data.length + 1;

      // if(this.data.length > 0) {
      //    // console.log(this.data);
      //    // let localCount = 0;
      //    // let localIdx = 0;
      //    this.data.forEach(d => {
      //       if(d._slug === el._slug) {
      //          // idx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
      //          let localIdx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
      //          idx = localIdx;
      //          this.data.splice(localIdx, 1);
      //          $(`.log--item[data-id="${el._slug}"]`).remove();
      //          // $(`.log--item[data-idx="${idx}"]`).remove();
      //          // localCount++;
      //          // $(this).attr('data-count', count);
      //       }
      //       // localIdx++;
      //    });
      // }
      if(this.data.indexOf(el) > -1) {
         idx = $(`.log--item[data-id="${el._slug}"]`).attr('data-idx');
         $(`.log--item[data-id="${el._slug}"]`).remove();
      } else {
         this.data.push(el);
      }
      
      let newItem = $(`<div class="log--item" data-count="${count}" data-idx="${idx}" data-id="${el._slug}"><span>${el.title}</span><em></em></div>`);
      newItem.hide();
      $('.log--content').prepend(newItem);
      $('.log-wrap').animate({ scrollLeft: 0 }, 500);
      newItem.show(1000);

      $(newItem).children('span').on('click', function(){
         // let id = this.dataset.id;
         if($(el._box).hasClass('open')) {
            return;
         } else {
            if($('.box.open').length > 0) {
               $('.box-backdrop').click();
            }
            setTimeout(function(){
               $(el._box).trigger('click');
            }, 1);
         }
      });

      $(newItem).children('em').on('click', function(){
         let id = this.parentNode.dataset.id;
         let idx = this.parentNode.dataset.idx;
         this.parentNode.remove();
         log.data.splice(idx, 1);
         $(`.box[data-id="${id}"]`).remove();
         log.update();        
      });
   }

   update() {
      // $('.log--content').html('');
      // this.data.forEach((l, idx) => {
      //    let name = l.title;
      //    let newItem = $(`<div class="log--item" data-idx="${idx}">${name}</div>`);
      //    newItem.hide();
      //    $('.log--content').append(newItem);
      //    newItem.show(500);
      // });
   }
}