import mainView from './mainView';

export default class Ux {
   constructor() {
      window.mainView = new mainView($('main').get(0));

      this.activeFilters = {
         search: '',
         theme: ''
      };

      // $('body').on('mousemove', function(e){
      //    var x = 100 * (e.pageX / window.innerWidth);
      //    var y = (130) * ((e.pageY - (window.innerHeight*.5)) / window.innerHeight) - 20;

      //    x = 50 + (x - 50) * -0.5;
      //    y = 50 + (y - 50) * 0.5;

      //    $('main').css({
      //       'perspective-origin' : `${x}% ${y}%`
      //    })
      // });

      $('nav.cats a').on('click', function(e){
         // e.preventDefault();
         $('nav.cats a.cur').removeClass('cur');
         $(this).addClass('cur');
         var t = $(this).attr('href').substring(1);
         console.log(t);
         $(`.boxes.act`).removeClass('act');
         $(`.boxes.${t}`).addClass('act');
         
         $('main').attr('class','').addClass(`nth-act-${$('.boxes.act').index()+1}`);
      });

      if($(`nav.cats a[href="${window.location.hash}"]`).length>0) {
         $(`nav.cats a[href="${window.location.hash}"]`).trigger('click');
      } else {
         $(`nav.cats a`).first().trigger('click');
      }

      // setTimeout(function(){
      //    $('main').attr('class','').addClass(`nth-act-${$('.boxes.act').index()+1}`);
      // }, 400);

      $('.box-backdrop').on('click', function(e){
         e.preventDefault();
         if(!window.preventClick) {
            if($('.box.open').length>0) {
               $('.box.open .close').click();
            } else {
               if(window.relators.length>0) {
                  window.relators[0].clearRelateds();
               }
            }
         }
      });

      this.bindRandom();

      this.initSearch();

      this.initFilters();

      this.initIsotope();

      this._mapRange = this._mapRange.bind(this);
   }

   bindRandom(){
      $('.toggle-unimportant').on('click', function(e){
         e.preventDefault();
         $('.box.unimportant').toggleClass('show');
         $(this).toggleClass('on');
      });

      $('.reset').on('click', function(e){
         e.preventDefault();
         // $('.search').val('').trigger('change');
         // $('.view-as').val('text').trigger('change');
         // $('.group-by').val('theme').trigger('change');
         // $('.theme-select').val('').trigger('change');
         $('main').css({
            'transform' : 'scale(0.5)'
         });
         window.mainView.state.scale = 0.5;
         window.mainView.state.translateX = 0;
         window.mainView.state.translateY = 0;
         if(window.isotopes) {
            window.isotopes[0].layout();
         }
         if(window.relators.length>0) {
            window.relators[0].clearRelateds();
         }
      });
   } 
   
   applyFilters() {
      clearTimeout(window.filterDebounce);
      window.filterDebounce = setTimeout(() => {
         window.isotopes.forEach(iso => {
            iso.arrange({
               filter: (el) => {
                  const textMatch = this.activeFilters.search === '' || $(el).find('.title').text().toLowerCase().indexOf(this.activeFilters.search) > -1;
                  const themeMatch = this.activeFilters.theme === '' || $(el).find('.theme').text() === this.activeFilters.theme;
                  return textMatch && themeMatch;
               }
            });
         });
      }, 100); // Debounce for performance
   }

   initSearch(){
      const self = this;
      $('input.search').on('keyup change', function(e){
         var val = $(e.target).val().toLowerCase();
         self.activeFilters.search = val;
         self.applyFilters();
         // console.log($(this).val());
         // var val = $(this).val().toLowerCase();

         // clearTimeout(window.searchBounce);
         // window.searchBounce = setTimeout(function(){
         //    window.isotopes.forEach(iso => {
         //       iso.arrange({
         //          // item element provided as argument
         //          filter: function( el ) {
         //             var text = $(el).text().toLowerCase();
         //             if(text.indexOf(val) > -1) return true;
         //             // return
         //             // return parseInt( number, 10 ) > 50;
         //          }
         //       });
         //    });
         // }, 350);
      });
   }

   _mapRange(value, in_min, in_max, out_min, out_max) {
      return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
   }

   // // Multiplier adjustment
   // const multiplier = Math.max(0.5, zoomAdjust * 2); // This will affect the scaling factor
   
   // // Calculate mouse position as a percentage of the viewport dimensions
   // var xPercent = (event.pageX / window.innerWidth) * 100;
   // var yPercent = (event.pageY / window.innerHeight) * 100;
   
   // // Directly map to -100% to 100%, then adjust based on the multiplier
   // // This maps 0% to -100% and 100% to 100%, then applies the multiplier adjustment
   // xPercent = (xPercent - 50) * 3; // Base range from -100% to 100%
   // yPercent = (yPercent - 50) * 3; // Base range from -100% to 100%
   
   // // Apply the multiplier. The subtraction of 0.5 from the multiplier adjusts the base effect to start at 0.
   // // Then scale and recenter to 50%
   // xPercent = 50 + (xPercent * (multiplier - 0.5));
   // yPercent = 50 + (yPercent * (multiplier - 0.5));
   
   // var transformCss = `translateX(${xTranslate}%) translateY(${yTranslate}%) scale(${zoomAdjust}) `;


   initFilters(){
      const self = this;
      console.log(window.entries.themes);

      var options = '<option value="">All</option>';
      window.entries.themes.forEach(t => {
         options += `<option value="${t}">${t}</option>`;
      });

      $('.theme-select').append(options);


      $('.theme-select').on('change', (e) => {
         var val = $(e.target).val();
         self.activeFilters.theme = val;
         self.applyFilters();
      });

      // $('.theme-select').on('change', function(){
      //    var val = $(this).val();
      //    isotopes.forEach(iso => {
      //       iso.arrange({
      //          filter: function(e){
      //             return val=='' ? true : $(e).find('.theme').text()==val
      //          }
      //       });
      //    });
      // });
   }

   initIsotope(){
      window.isoArrangeCount = 0;
      window.isotopesInitialArrangementOk = false;

      $('.boxes-inner').each(function(){
         var boxes = this;
         var iso = new isotope( boxes, {
            // options...
            stagger: 0,
            transitionDuration: 300,
            itemSelector: '.box',
            layoutMode: 'masonry',
            masonry: {
               // gutter: 400,
               columnWidth: 40,
               isFitWidth: false
            },
            // isFitWidth: true,
            percentPosition: true,
            // packery: {
            //    gutter: 10
            // },
            getSortData: {
               theme: '[data-theme]',
               title: '.title',
            },
            sortBy: 'title',
            initLayout: true,
            // masonry: {
            //    percentPosition: true
            //    // columnWidth: 200
            // }
         });

         // Listen for arrangeComplete event
         iso.on('layout', function(items) {
            // iso.element.style.opacity = 0;
         });
         iso.on('layoutComplete', function(items) {
            // iso.element.style.opacity = 1;
            // if(window.relators.length>0) {
            //    window.relators[0].clearRelateds();
            // }
            for(var i=0;i<$('.hovering').length;i++){
               lastbox.alignRelatedBoxes($('.hovering').get(i));
            }
            if($('.box.open').length == 0) {
               $('.footer').addClass('in');
            }
         });
         iso.on('arrange', function(items) {
            // iso.element.style.opacity = 0;
         });
         iso.on('arrangeComplete', function(items) {
            // console.log(`%cArrange complete`, 'color: #f00');
            // items: an array of Isotope.Items that were laid out
            items.forEach(function(item) {
               item.element.style.width = '';
               // Directly set the style on item's element
               setTimeout(function(){
                  var outerWidth = item.element.offsetWidth; // Using offsetWidth as a stand-in for outerWidth
                  item.element.style.width = outerWidth + 'px';
               }, 1);
            });

            window.isoArrangeCount++;
            
            if(window.isoArrangeCount >= window.isotopes.length && !window.isotopesInitialArrangementOk) {
               console.log('All isotopes arranged');
               isotopesInitialArrangementOk = true;
               if($('.box.open').length == 0) {
                  $('.footer').addClass('in');
               }
               // clearInterval(window.loadInterval);
               // $('main').addClass('loaded');
            }

            iso.element.style.opacity = 1;

            // if(window.relators.length>0) {
            //    window.relators[0].clearRelateds();
            // }

            for(var i=0;i<$('.hovering').length;i++){
               lastbox.alignRelatedBoxes($('.hovering').get(i));
            }
         });

         // setTimeout(function(){
         //    iso.arrange();
         // }, 10);

         if(!window.isotopes) window.isotopes = [];
         window.isotopes.push(iso);
      });

      $('.view-as').on('change', function(){
         var val = $(this).val();
         $('content.boxes').attr('data-view-as', val);
         window.isotopes.forEach(iso => {
            iso.layout();
         });
      });

      $('.group-by').on('change', function(){
         var val = $(this).val();
         isotopes.forEach(iso => {
            iso.arrange({
               sortBy: val
            });
         });
      });

      $('.reorder').on('click', function(e){
         e.preventDefault();
         var val = $(this).val();
         isotopes.forEach(iso => {
            iso.layout();
         });
      });

      $('.footer').addClass('in');
   }
}